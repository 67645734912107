:root {
  --main-color: #AB861E;
  --background: #F0F0F0;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
  *{
      font-family: sans-serif;
      font-weight: 400;
      line-height: 1.4;
      color: #212121;
  }
  b{
    font-weight: 600;
  }
  .secontFont{
    font-family: Montserrat;
  }
  .login,.caratura{
      width: 100%;
  }
  .caratura{
    background: no-repeat center / cover url("image/fondo.svg");
    height: 100vh;
    display: none;
  }
  .gap32{
    gap: 32px;
  }
  .login section img{
    max-width: 210px;
  }
  .inputCustom{
    border: solid 2px #A8A8A8;
    border-radius: 4px;
    position: relative;
    width: 82%;
  }

  .inputCustom .icon{
    position: absolute;
    top: 14px;
    left: 18px;
  }
  .inputCustom input{
    border: 0;
    padding: 16px 16px 16px 50px;
    width: 100%;
  }
  .colorMain{
    color: var(--main-color);
  }
  .backgroundMainColor{
    background-color: var(--main-color) ;
  }
  .borderMainColor{
    border: solid 1px var(--main-color);
  }
  .backgroundMain{
      background-color: var(--background);
  }
  button:hover.backgroundMainColor{
      background-color: #392D0C;
  }
  .minHeight100vh{
      min-height: 100vh;
  }

  .accordion-button:not(.collapsed){
    color: #212121;
    background-color: white;
    box-shadow: inset 0 calc(-1* var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
  }
  .accordion-button:not(.collapsed)::after {
    transform: var(--bs-accordion-btn-icon-transform);
  }
  .accordion-button::after{
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .cardPago{
    min-width: 360px;
  }
  .cardPago .form-check-input{
    width: 2em;
    height: 2em;
    border: 3px solid var(--background);
  } 
  .cardPago .form-check-input:checked{
    background-color: var(--main-color);
    border-color: var(--main-color);
  }
  .descuento{
    top: 16px;left: 2px; width: 70px;
  }
  .descuentoTexto{
    top: 37px;left: 2px;width: 70px;font-size: 12px;
  }
  .alertCorrect{
    border:solid 1px #09632D;
    color: #09632D;
    background: #DEFCE6;
  }
  @media (min-width: 992px) {
    .login{
      width: 60%;
    }
    .login section div{
      max-width: 700px;
    }
    .caratura{
      width: 40%;
      display: inherit;
    }
    .minHeight100vhLg{
      min-height: 100vh;
    }
  }
  @media (min-width: 1200px){
    .login{
      width: 70%;
    }
    .caratura{
      width: 30%;
    }
  }
  @media (min-width: 1400px) {
    .login{
      width: 65%;
    }
    .caratura{
      width: 35%;
    }
  } 